/**
 * Tilled Admin API
 * Tilled Internal Admin API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: integrations@tilled.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { BalanceTransactionFeeDetail } from './balanceTransactionFeeDetail';


export interface BalanceTransaction { 
    account_id: string;
    /**
     * Gross amount of the transaction, in the smallest currency unit (e.g., 100 cents to charge $1.00 or 100 to charge ¥100, a zero-decimal currency). Can be negative for certain transaction types (e.g. `payout`, `refund`).
     */
    amount: number;
    /**
     * The date the transaction\'s net funds will become available in the Tilled balance.
     */
    available_on: string;
    /**
     * Time at which the object was created.
     */
    created_at: string;
    /**
     * Three-letter [ISO currency code](https://www.iso.org/iso-4217-currency-codes.html), in lowercase.
     */
    currency: BalanceTransaction.CurrencyEnum;
    /**
     * An arbitrary string attached to the object. Often useful for displaying to users.
     */
    description?: string;
    /**
     * Fees (in smallest currency unit) paid for this transaction. Note: Fees are now portrayed as individual balance transactions of `type = fee`.
     * @deprecated
     */
    fee: number;
    /**
     * Detailed breakdown of the fees paid for this transaction. Note: Fees are now portrayed as individual balance transactions of `type = fee`. Refer to `fee_type` and `fee_subtype` for details.
     * @deprecated
     */
    fee_details: Array<BalanceTransactionFeeDetail>;
    /**
     * Fee subtype, if this transaction represents a fee (i.e. type = fee).
     */
    fee_subtype?: BalanceTransaction.FeeSubtypeEnum;
    /**
     * Fee type, if this transaction represents a fee (i.e. type = fee).
     */
    fee_type?: BalanceTransaction.FeeTypeEnum;
    /**
     * Unique identifier for the object.
     */
    id: string;
    /**
     * Net amount of the transaction, in smallest currency unit. Note: Fees are now portrayed as individual balance transactions of `type = fee`. `net` will no longer differ from `amount`.
     * @deprecated
     */
    net: number;
    /**
     * The id of the Payout, if any, associated with this balance transaction.
     */
    payout_id?: string;
    /**
     * Tilled id to which this transaction is related
     */
    source_id: string;
    /**
     * Tilled resource type of `source_id`.
     */
    source_type: BalanceTransaction.SourceTypeEnum;
    /**
     * If the transaction’s net funds are available in the Tilled balance yet. Either `available` or `pending`.
     */
    status: BalanceTransaction.StatusEnum;
    /**
     * Transaction type.
     */
    type: BalanceTransaction.TypeEnum;
    /**
     * Time at which the object was last updated.
     */
    updated_at: string;
}
export namespace BalanceTransaction {
    export type CurrencyEnum = 'aud' | 'cad' | 'dkk' | 'eur' | 'hkd' | 'jpy' | 'nzd' | 'nok' | 'gbp' | 'zar' | 'sek' | 'chf' | 'usd';
    export const CurrencyEnum = {
        AUD: 'aud' as CurrencyEnum,
        CAD: 'cad' as CurrencyEnum,
        DKK: 'dkk' as CurrencyEnum,
        EUR: 'eur' as CurrencyEnum,
        HKD: 'hkd' as CurrencyEnum,
        JPY: 'jpy' as CurrencyEnum,
        NZD: 'nzd' as CurrencyEnum,
        NOK: 'nok' as CurrencyEnum,
        GBP: 'gbp' as CurrencyEnum,
        ZAR: 'zar' as CurrencyEnum,
        SEK: 'sek' as CurrencyEnum,
        CHF: 'chf' as CurrencyEnum,
        USD: 'usd' as CurrencyEnum
    };
    export type FeeSubtypeEnum = 'approved_verification' | 'declined_verification' | 'approved_authorization' | 'declined_authorization' | 'reversed_authorization' | 'completed_credit' | 'declined_credit' | 'completed_settlement' | 'declined_settlement' | 'approved_purchase_return_authorization' | 'declined_purchase_return_authorization' | 'acquirer_processing' | 'amex_card_not_present' | 'amex_dues_and_assessments' | 'amex_inbound' | 'amex_international_assessment' | 'discover_assessment' | 'discover_data_usage' | 'dues_and_assessments' | 'fixed_acquirer_network' | 'interlink_switch' | 'interlink_pin_authenticated' | 'interlink_regulated' | 'mastercard_acquirer_brand_volume' | 'mastercard_acquirer_merchant_advice_code' | 'mastercard_acquirer_program_support' | 'mastercard_annual_location' | 'mastercard_assessment' | 'mastercard_assessment_1000_plus' | 'mastercard_authorization' | 'mastercard_cross_border_scheme' | 'mastercard_digital_commerce_development' | 'mastercard_digital_enablement' | 'mastercard_digital_enablement_max' | 'mastercard_final_authorization' | 'mastercard_final_authorization_min' | 'mastercard_international_assessment' | 'mastercard_nominal_amount_authorization' | 'mastercard_processing_integrity' | 'mastercard_safety_net_acquirer' | 'misuse_of_authorization' | 'nabu' | 'network_debit' | 'transaction_integrity' | 'visa_acquirer_credit_voucher_international' | 'visa_assessment' | 'visa_base_ii_transmission' | 'visa_commercial_solution' | 'visa_debit_assessment' | 'visa_international_assessment' | 'visa_misuse_of_authorization' | 'visa_us_digital_commerce' | 'reversal' | 'discount' | 'transaction' | 'account_setup' | 'ach_return' | 'monthly_account' | 'monthly_minimum_processing' | 'card_account_update' | 'monthly_terminal' | 'retrieval' | 'apple_pay_balance_transfer' | 'chargeback' | 'goods_and_services' | 'harmonized_sales';
    export const FeeSubtypeEnum = {
        APPROVED_VERIFICATION: 'approved_verification' as FeeSubtypeEnum,
        DECLINED_VERIFICATION: 'declined_verification' as FeeSubtypeEnum,
        APPROVED_AUTHORIZATION: 'approved_authorization' as FeeSubtypeEnum,
        DECLINED_AUTHORIZATION: 'declined_authorization' as FeeSubtypeEnum,
        REVERSED_AUTHORIZATION: 'reversed_authorization' as FeeSubtypeEnum,
        COMPLETED_CREDIT: 'completed_credit' as FeeSubtypeEnum,
        DECLINED_CREDIT: 'declined_credit' as FeeSubtypeEnum,
        COMPLETED_SETTLEMENT: 'completed_settlement' as FeeSubtypeEnum,
        DECLINED_SETTLEMENT: 'declined_settlement' as FeeSubtypeEnum,
        APPROVED_PURCHASE_RETURN_AUTHORIZATION: 'approved_purchase_return_authorization' as FeeSubtypeEnum,
        DECLINED_PURCHASE_RETURN_AUTHORIZATION: 'declined_purchase_return_authorization' as FeeSubtypeEnum,
        ACQUIRER_PROCESSING: 'acquirer_processing' as FeeSubtypeEnum,
        AMEX_CARD_NOT_PRESENT: 'amex_card_not_present' as FeeSubtypeEnum,
        AMEX_DUES_AND_ASSESSMENTS: 'amex_dues_and_assessments' as FeeSubtypeEnum,
        AMEX_INBOUND: 'amex_inbound' as FeeSubtypeEnum,
        AMEX_INTERNATIONAL_ASSESSMENT: 'amex_international_assessment' as FeeSubtypeEnum,
        DISCOVER_ASSESSMENT: 'discover_assessment' as FeeSubtypeEnum,
        DISCOVER_DATA_USAGE: 'discover_data_usage' as FeeSubtypeEnum,
        DUES_AND_ASSESSMENTS: 'dues_and_assessments' as FeeSubtypeEnum,
        FIXED_ACQUIRER_NETWORK: 'fixed_acquirer_network' as FeeSubtypeEnum,
        INTERLINK_SWITCH: 'interlink_switch' as FeeSubtypeEnum,
        INTERLINK_PIN_AUTHENTICATED: 'interlink_pin_authenticated' as FeeSubtypeEnum,
        INTERLINK_REGULATED: 'interlink_regulated' as FeeSubtypeEnum,
        MASTERCARD_ACQUIRER_BRAND_VOLUME: 'mastercard_acquirer_brand_volume' as FeeSubtypeEnum,
        MASTERCARD_ACQUIRER_MERCHANT_ADVICE_CODE: 'mastercard_acquirer_merchant_advice_code' as FeeSubtypeEnum,
        MASTERCARD_ACQUIRER_PROGRAM_SUPPORT: 'mastercard_acquirer_program_support' as FeeSubtypeEnum,
        MASTERCARD_ANNUAL_LOCATION: 'mastercard_annual_location' as FeeSubtypeEnum,
        MASTERCARD_ASSESSMENT: 'mastercard_assessment' as FeeSubtypeEnum,
        MASTERCARD_ASSESSMENT_1000_PLUS: 'mastercard_assessment_1000_plus' as FeeSubtypeEnum,
        MASTERCARD_AUTHORIZATION: 'mastercard_authorization' as FeeSubtypeEnum,
        MASTERCARD_CROSS_BORDER_SCHEME: 'mastercard_cross_border_scheme' as FeeSubtypeEnum,
        MASTERCARD_DIGITAL_COMMERCE_DEVELOPMENT: 'mastercard_digital_commerce_development' as FeeSubtypeEnum,
        MASTERCARD_DIGITAL_ENABLEMENT: 'mastercard_digital_enablement' as FeeSubtypeEnum,
        MASTERCARD_DIGITAL_ENABLEMENT_MAX: 'mastercard_digital_enablement_max' as FeeSubtypeEnum,
        MASTERCARD_FINAL_AUTHORIZATION: 'mastercard_final_authorization' as FeeSubtypeEnum,
        MASTERCARD_FINAL_AUTHORIZATION_MIN: 'mastercard_final_authorization_min' as FeeSubtypeEnum,
        MASTERCARD_INTERNATIONAL_ASSESSMENT: 'mastercard_international_assessment' as FeeSubtypeEnum,
        MASTERCARD_NOMINAL_AMOUNT_AUTHORIZATION: 'mastercard_nominal_amount_authorization' as FeeSubtypeEnum,
        MASTERCARD_PROCESSING_INTEGRITY: 'mastercard_processing_integrity' as FeeSubtypeEnum,
        MASTERCARD_SAFETY_NET_ACQUIRER: 'mastercard_safety_net_acquirer' as FeeSubtypeEnum,
        MISUSE_OF_AUTHORIZATION: 'misuse_of_authorization' as FeeSubtypeEnum,
        NABU: 'nabu' as FeeSubtypeEnum,
        NETWORK_DEBIT: 'network_debit' as FeeSubtypeEnum,
        TRANSACTION_INTEGRITY: 'transaction_integrity' as FeeSubtypeEnum,
        VISA_ACQUIRER_CREDIT_VOUCHER_INTERNATIONAL: 'visa_acquirer_credit_voucher_international' as FeeSubtypeEnum,
        VISA_ASSESSMENT: 'visa_assessment' as FeeSubtypeEnum,
        VISA_BASE_II_TRANSMISSION: 'visa_base_ii_transmission' as FeeSubtypeEnum,
        VISA_COMMERCIAL_SOLUTION: 'visa_commercial_solution' as FeeSubtypeEnum,
        VISA_DEBIT_ASSESSMENT: 'visa_debit_assessment' as FeeSubtypeEnum,
        VISA_INTERNATIONAL_ASSESSMENT: 'visa_international_assessment' as FeeSubtypeEnum,
        VISA_MISUSE_OF_AUTHORIZATION: 'visa_misuse_of_authorization' as FeeSubtypeEnum,
        VISA_US_DIGITAL_COMMERCE: 'visa_us_digital_commerce' as FeeSubtypeEnum,
        REVERSAL: 'reversal' as FeeSubtypeEnum,
        DISCOUNT: 'discount' as FeeSubtypeEnum,
        TRANSACTION: 'transaction' as FeeSubtypeEnum,
        ACCOUNT_SETUP: 'account_setup' as FeeSubtypeEnum,
        ACH_RETURN: 'ach_return' as FeeSubtypeEnum,
        MONTHLY_ACCOUNT: 'monthly_account' as FeeSubtypeEnum,
        MONTHLY_MINIMUM_PROCESSING: 'monthly_minimum_processing' as FeeSubtypeEnum,
        CARD_ACCOUNT_UPDATE: 'card_account_update' as FeeSubtypeEnum,
        MONTHLY_TERMINAL: 'monthly_terminal' as FeeSubtypeEnum,
        RETRIEVAL: 'retrieval' as FeeSubtypeEnum,
        APPLE_PAY_BALANCE_TRANSFER: 'apple_pay_balance_transfer' as FeeSubtypeEnum,
        CHARGEBACK: 'chargeback' as FeeSubtypeEnum,
        GOODS_AND_SERVICES: 'goods_and_services' as FeeSubtypeEnum,
        HARMONIZED_SALES: 'harmonized_sales' as FeeSubtypeEnum
    };
    export type FeeTypeEnum = 'discount' | 'transaction' | 'interchange' | 'pass_through' | 'platform' | 'chargeback' | 'administrative' | 'tax' | 'negative_rounding_adjustment' | 'positive_rounding_adjustment' | 'unknown';
    export const FeeTypeEnum = {
        DISCOUNT: 'discount' as FeeTypeEnum,
        TRANSACTION: 'transaction' as FeeTypeEnum,
        INTERCHANGE: 'interchange' as FeeTypeEnum,
        PASS_THROUGH: 'pass_through' as FeeTypeEnum,
        PLATFORM: 'platform' as FeeTypeEnum,
        CHARGEBACK: 'chargeback' as FeeTypeEnum,
        ADMINISTRATIVE: 'administrative' as FeeTypeEnum,
        TAX: 'tax' as FeeTypeEnum,
        NEGATIVE_ROUNDING_ADJUSTMENT: 'negative_rounding_adjustment' as FeeTypeEnum,
        POSITIVE_ROUNDING_ADJUSTMENT: 'positive_rounding_adjustment' as FeeTypeEnum,
        UNKNOWN: 'unknown' as FeeTypeEnum
    };
    export type SourceTypeEnum = 'charge' | 'refund' | 'payout' | 'payment_method' | 'platform_fee' | 'platform_fee_refund' | 'dispute' | 'account' | 'outbound_transfer';
    export const SourceTypeEnum = {
        CHARGE: 'charge' as SourceTypeEnum,
        REFUND: 'refund' as SourceTypeEnum,
        PAYOUT: 'payout' as SourceTypeEnum,
        PAYMENT_METHOD: 'payment_method' as SourceTypeEnum,
        PLATFORM_FEE: 'platform_fee' as SourceTypeEnum,
        PLATFORM_FEE_REFUND: 'platform_fee_refund' as SourceTypeEnum,
        DISPUTE: 'dispute' as SourceTypeEnum,
        ACCOUNT: 'account' as SourceTypeEnum,
        OUTBOUND_TRANSFER: 'outbound_transfer' as SourceTypeEnum
    };
    export type StatusEnum = 'available' | 'pending';
    export const StatusEnum = {
        AVAILABLE: 'available' as StatusEnum,
        PENDING: 'pending' as StatusEnum
    };
    export type TypeEnum = 'charge' | 'charge_failure_refund' | 'dispute' | 'dispute_won' | 'refund' | 'refund_failure' | 'adjustment' | 'commission' | 'payout' | 'payout_cancel' | 'payout_failure' | 'fee' | 'platform_fee' | 'platform_fee_refund' | 'outbound_transfer' | 'outbound_transfer_cancel' | 'outbound_transfer_failure' | 'charge_fee' | 'refund_fee' | 'account_fee' | 'payment_method_fee' | 'tilled_fee';
    export const TypeEnum = {
        CHARGE: 'charge' as TypeEnum,
        CHARGE_FAILURE_REFUND: 'charge_failure_refund' as TypeEnum,
        DISPUTE: 'dispute' as TypeEnum,
        DISPUTE_WON: 'dispute_won' as TypeEnum,
        REFUND: 'refund' as TypeEnum,
        REFUND_FAILURE: 'refund_failure' as TypeEnum,
        ADJUSTMENT: 'adjustment' as TypeEnum,
        COMMISSION: 'commission' as TypeEnum,
        PAYOUT: 'payout' as TypeEnum,
        PAYOUT_CANCEL: 'payout_cancel' as TypeEnum,
        PAYOUT_FAILURE: 'payout_failure' as TypeEnum,
        FEE: 'fee' as TypeEnum,
        PLATFORM_FEE: 'platform_fee' as TypeEnum,
        PLATFORM_FEE_REFUND: 'platform_fee_refund' as TypeEnum,
        OUTBOUND_TRANSFER: 'outbound_transfer' as TypeEnum,
        OUTBOUND_TRANSFER_CANCEL: 'outbound_transfer_cancel' as TypeEnum,
        OUTBOUND_TRANSFER_FAILURE: 'outbound_transfer_failure' as TypeEnum,
        CHARGE_FEE: 'charge_fee' as TypeEnum,
        REFUND_FEE: 'refund_fee' as TypeEnum,
        ACCOUNT_FEE: 'account_fee' as TypeEnum,
        PAYMENT_METHOD_FEE: 'payment_method_fee' as TypeEnum,
        TILLED_FEE: 'tilled_fee' as TypeEnum
    };
}


